.Container{
  background-color: #F4F7FA;

;
}
.App-header{
  display: flex;
  font-family: "TT Hoves",sans-serif;
  color: #22292E;
  /*font-style: normal;*/
  /*font-size: 20px;*/
  /*font-weight: 600;*/
  Line-height:28px;
  margin-left:16px;
  /*margin-top:14px;*/
  align-items: center;
  gap: 91px;
}
.logoClass{
  width: 40px;
  height: 40px;
  margin-left: 47px;
}
@media  (max-width: 1800px){
  .Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-width: 100%;
    margin: 0 auto;
  }
}




